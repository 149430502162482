.bootstrap-navbar-onload{
    position: fixed !important;
    width: 90% !important;
    /* background: transparent !important; */
    margin-left: 5% !important;
    /* opacity: 1; */
    z-index: 1000 !important;
    background: #eaeaead1 !important;
    border-radius: 25px;
    margin-top: 10px;
}
.navbar-brand {
    color:rgb(114 140 148);
}
.offcanvas-body{
    padding: 0px;
}
/* .bootstrap-navbar-onload .sticky-navbar {
    background-color: wheat !important;
} */


@media (max-width: 576px)  {
    .offcanvas-body{
        background: repeat center / 11px url("../images/dot.png") #fff !important;
    }
    .nav-link{
        width: 100%;
        border-bottom: 1px solid #0000004f;
        font-size: 20px;
        color: #4e6773;
        cursor: pointer;
    }
    
}