.form-container {
    width: 90%;
    max-width: 600px;
    /* background: linear-gradient(135deg, #007bff, #00c6ff); */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* margin: auto; */
    font-family: Arial, sans-serif;
    color: #333;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    height: max-content;
    margin-top: 90px;
  }
  
  h2 {
    text-align: center;
    /* color: #ffffff; */
    margin-bottom: 1rem;
  }
  
  .quote-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .input-group {
    position: relative;
  }
  
  input[type="text"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 10px 10px 10px 5px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
  }
  
  input[type="text"]:focus,
  input[type="number"]:focus,
  textarea:focus {
    border-color: #007bff;
  }
  
  label {
    position: absolute;
    left: 15px;
    top: 10px;
    color: #888;
    font-size: 0.85rem;
    pointer-events: none;
    transition: 0.2s ease;
  }
  
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label {
    top: -12px;
    left: 10px;
    background: #ffffff;
    padding: 0 5px;
    color: #007bff;
    font-size: 0.75rem;
  }
  
  .radio-group {
    display: flex;
    gap: 1rem;
  }
  
  .radio-option {
    padding: 8px 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .radio-option.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .radio-option input {
    display: none;
  }
  
  textarea {
    min-height: 80px;
  }
  
  button[type="submit"] {
    padding: 10px;
    font-size: 1rem;
    color: #ffffff;
    background: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  button[type="submit"]:hover {
    background: #0056b3;
  }
  