/* src/components/ProductPage.css */
:root {
    --primary-color: #0066cc;
    --secondary-color: #f7f7f7;
    --text-color: #333;
    --border-radius: 10px;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: var(--secondary-color);
    color: var(--text-color);
  }
  
  .container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
  }
  
  .title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    color: var(--primary-color);
    letter-spacing: 2px;
    font-weight: bold;
  }
  
  .product-card {
    background: white;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  .product-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .product-details {
    padding: 20px;
  }
  
  .product-details h2 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: var(--primary-color);
    text-transform: uppercase;
  }
  
  .product-details p {
    margin-bottom: 10px;
    font-size: 1.1rem;
    line-height: 1.5;
  }
  
  .product-details ul {
    /* list-style-type: disc; */
    list-style-type: none;
    text-align: start;
    margin-left: 20px;
    font-size: 1rem;
  }
  
  .product-details ul li {
    margin-bottom: 5px;
  }
  
  .sub-product {
    display: flex;
    align-items: center;
    margin-top: 20px;
    background-color: var(--secondary-color);
    padding: 10px;
    border-radius: var(--border-radius);
    transition: background-color 0.3s ease;
  }
  
  .sub-product:hover {
    background-color: #e6f2ff;
  }
  
  .sub-product-image {
    width: 100px;
    height: 100px;
    margin-right: 15px;
    border-radius: var(--border-radius);
    object-fit: cover;
  }
  
  .sub-product-details h4 {
    margin: 0;
    font-size: 1.2rem;
    color: var(--primary-color);
    text-align: start;
  }
  
  .product-video {
    width: 100%;
    margin-top: 20px;
    border-radius: var(--border-radius);
  }
  
  .service-list {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    padding: 0;
    max-width: 800px;
    margin: 40px auto;
  }
  
  .service-list li {
    font-size: 1.1rem;
    background: white;
    padding: 15px;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .service-list li:hover {
    transform: scale(1.05);
  }
  .sub-product-list{
    list-style-type: none;
  }
  /* General Container */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* Title Styling */
.title {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

/* Product Card */
.product-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 20px;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Product Details */
.product-details {
  padding: 10px;
}

.product-details h2 {
  font-size: 2rem;
  color: #444;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.product-details p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Sub-Product Styling */
.sub-product {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.sub-product-details h4 {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 5px;
}

.sub-product-details p {
  font-size: 1rem;
  color: #777;
  line-height: 1.5;
}

/* Sub-Product List */
.sub-product-list {
  padding-left: 20px;
  margin-bottom: 20px;
}

.sub-product-list li {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 8px;
}

/* Product Image */
.product-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

/* Sub-Product Image */
.sub-product-image {
  width: 80px;
  height: 80px;
  margin-right: 15px;
  border-radius: 5px;
  object-fit: cover;
}

/* Product Video */
.product-video {
  max-width: 100%;
  margin-top: 20px;
  border-radius: 8px;
}

/* Services Section */
.service-list {
  list-style: none;
  padding-left: 0;
  margin-top: 20px;
}

.service-list li {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 10px;
}

.sub-prod-description{
  text-align: left;
}
  @media screen and (min-width: 768px) {
    .product-card {
      flex-direction: row;
      height: auto;
    }
  
    .product-image {
      width: 50%;
      max-width: 400px;
      height: auto;
      margin-right: 20px;
    }
  
    /* .product-details {
      width: 50%;
    } */
  }
  