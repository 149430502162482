/* Container styles */
.enquiry {
  bottom: 11%;
  position: fixed;
  height: 74%;
  top: 15%;
  right: 10px;
  width: 300px;
  z-index: 1000;
  background: #6c878fe6;
  border: #ffffff4a 0.5px solid;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

/* Header styles */
.enquiry-header-content {
  background: #d1d1d1;
  display: flex;
  align-items: center;
  padding: 10px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.enquiry-header-content img {
  margin-right: 10px;
}

/* Form container styles */
.contact-form {
  padding: 20px;
}

/* Input and textarea styles */
.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  margin-top: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  font-size: 14px;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  display:none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .enquiry {
    width: 85%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 480px) {
  .enquiry {
    width: 90%;
  }
}

/* Button styles */
.EnquirySubmitBtn {
  background: #4caf50;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  right: 20px;
  font-size: 18px;
  transition: background 0.3s ease;
}

.EnquirySubmitBtn:hover {
  background: #45a049;
}

/* Icon inside the button */
.EnquirySubmitBtn svg {
  stroke: #fff;
}

/* Textarea specific adjustments */
.form-group textarea {
  resize: none;
  height: 80px;
  margin-top: 12px;
}
