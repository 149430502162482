/* src/components/Footer.css */

.footer {
    background-color: #343a40;
    color: #ffffff;
  }
  
  .footer a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  
  .footer ul {
    padding: 0;
    list-style-type: none;
  }
  
  .footer h5 {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .footer i {
    margin-right: 0.5rem;
  }
  
  .footer ul.d-flex li {
    margin-right: 1rem;
  }
  