/* Product.css */

.product-page {
    /* padding: 20px; */
    text-align: center;
    padding-top: 70px;
  }
  
  .product-page h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    overflow: visible;
  }
  
  .product-list {
    display: flex;
    justify-content: center;
    /* flex-wrap: wrap; */
    gap: 20px;
  }
  
  .product-item {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 200px;
    text-align: center;
    transition: transform 0.3s;
  }
  
  .product-item:hover {
    transform: scale(1.05);
  }
  
  .product-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .product-description {
    font-size: 1rem;
    margin: 10px 0;
  }
  
  .product-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #28a745;
  }
  .product-category h2 {
    font-weight: bold;
    color: #2C3E50;
  }
  
  .card-img-top {
    max-height: 200px;
    object-fit: cover;
    padding: 10px;
  }
  
  .card-body {
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .card-img-top {
      max-height: 150px;
      padding: 10px;
    }

    .product-list{
      flex-wrap: wrap;
    }
  }
  