.login-page{
    padding-top: 95px;
    height: 100vh;
}
.login-contianer{
    width: 40%;
    background: #ff00c638;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 5px 5px 15px 1px #ff00c669;
}