/* * {
  overflow-x: hidden;
} */
html, body {
  height: 100%;
  margin: 0;
  background:#f3fbfd;
}

/* Set the app container to use flexbox */
#root, .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.main-container{
 display: none ;
}

/* Push the footer to the bottom */
.App > .content {
  flex: 1;
}

/* Footer styles */
.footer {
  background-color: #343a40;
  color: #ffffff;
  padding: 1rem;
  text-align: center;
}
.App-logo {
  height: 7vmin;
  pointer-events: none;
  margin-right: auto;
}

.header-columns {
  padding: 0px !important;
}

.header-logo {
  width: fit-content !important;
}

.App-header {
  background-color: transparent;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  font-family: 'Poppins', sans-serif;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  padding-top:10px;
}

.container.sticky {
  background-color: #f9fcfd;
  box-shadow: 0px 0px 5px rgb(105 133 142);
  /* padding: 10px; */
  padding: 10px 0px 0px 10px;
  border-radius: 10px;
  margin-top: 5px;
}



/* Footer styles to ensure it sticks to the bottom only when there's enough content */
footer {
  margin-top: auto; /* Pushes the footer to the bottom when content grows */
}


.company-name {
  color: #08a7c4;
  font-size: 16px;
}

.enquiry-button {
  position: relative;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  letter-spacing: 0.02857em;
  min-width: 64px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 0.5rem;
  line-height: 1.4;
  text-align: center;
  text-transform: uppercase;
  user-select: none;
  background-size: 150% !important;
  background-position-x: 25% !important;
  transition: all 150ms ease-in;
  min-height: 2.5rem;
  padding: 0.625rem 1.5rem;
  background: transparent;
  border: 1px solid #e2cc47;
  color: #0c9ab4;
}
.chakra-button {
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  vertical-align: middle;
  outline-offset: 2px;
  line-height: 1.2;
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: 60px;
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  color: aliceblue;
  box-shadow: var(--chakra-shadows-md);
  width: 60px;
  background-image: linear-gradient( rgb(119 144 152) 0%,  rgb(128, 90, 213) 81.85%);
  display: flex;
  white-space: nowrap;
  outline: transparent solid 2px;
  margin: 12px 20px 20px;
  border-radius: 99px;
  padding: 30px;
  border: none;
  position: fixed;
  bottom: 0px;
  right: 5px;
}

.chakra-button:hover{
  transition: transform 250ms cubic-bezier(0.33, 0, 0, 1);
    transform: scale(1.05);
}

.chakra-icon {
  display: inline-block;
  line-height: 1em;
  flex-shrink: 0;
  color: currentcolor;
  vertical-align: middle;
  fill: none;
  height: 37px;
  width: 34px;
  position: absolute;
  opacity: 1;
  transition: transform 0.16s linear, opacity 0.08s linear;
  transform: rotate(0deg);
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-30deg);
  }
}

.rotate {
  animation: rotate360 0.3s ease-in-out;
}

/* Responsive font sizes and layout adjustments */
@media (max-width: 768px) {
  .company-name-content {
    font-size: 4em;
  }

  .company-discripition {
    font-size: 1em;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin: 5px 0;
  }
}
/* Width and height of the scrollbar */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Background of the scrollbar */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* border-radius: 10px; */
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #9daeb5;
  /* border-radius: 10px; */
}

/* Scrollbar thumb when hovered */
::-webkit-scrollbar-thumb:hover {
  background: #4e6773;
}
@media (max-width: 480px) {
  .enquiry-button {
    padding: 6px 12px;
    font-size: 12px;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .nav-links li {
    margin: 5px 0;
  }
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.nav-links li a {
  text-decoration: none;
  color: #ffff;
  font-weight: bold;
  transition: color 0.3s ease;
}

.nav-links.sticky-color li a {
  color: #5f868f !important;
}

.nav-links li a:hover {
  color: #007bff;
}
