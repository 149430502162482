.loading-bar{
    height: 100vh;
    background-color: #000000;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .loading-bar-img{
        width: 100vw;
    }
}