body {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .contact-section {
    display: flex; 
    flex-direction: column;
    align-items: center;
    padding: 75px 0;
    background-color: #f8f9fa;
  }
  
  .contact-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .contact-form-container {
    flex: 1;
    padding: 20px;
    background-color: white;
    min-width: 300px;
    display: flex;
    flex-direction: column;
  }
  
  .form-container h4 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    resize: none;
  }
  
  textarea {
    height: 100px;
  }
  
  .btn-submit {
    padding: 12px 20px;
    background-color: #ff4d7e;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .btn-submit:hover {
    background-color: #ff6a5b;
  }
  
  .map-container {
    flex: 1;
    min-width: 300px;
    position: relative;
  }
  
  .map-container iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  
  .contact-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .contact-info {
    margin-right: 40px;
  }
  
  .contact-info h5 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #333;
  }
  
  .contact-info p {
    margin: 0;
    font-size: 16px;
    color: #666;
  }

  .contactGifs{
    height: 40px;
    margin-right: 5px;
  }
  .gif-container{
    display: flex;
    align-items: center;
  }
  
  @media screen and (max-width: 768px) {
    .contact-container {
      flex-direction: column;
    }
  
    .form-container, .map-container {
      min-width: 100%;
    }
  
    .contact-details {
      flex-direction: column;
      align-items: center;
    }
  
    .contact-info {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  
