.container-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container-1, .container-2, .container-3 {
  background-color: #FBF0F1; /* Example background color */
  margin-right: 20px; /* Adjust the margin as needed */
  transform: rotate(-10deg) translateY(0); /* Initial tilt and position */
  border-radius: 15px; /* Adjust border radius as needed */
  transition: transform 0.3s ease-in-out; /* Smooth transition for transform */
  height: 30%;
}

.container-1:hover, .container-2:hover, .container-3:hover {
  transform: rotate(-10deg) translateY(-10px); 
}

.container-2 {
  background-color: antiquewhite;
  transform: rotate(-7deg); /* Adjust tilt for container-2 */
}

.container-3 {
  background-color: aqua;
  transform: rotate(-3deg); /* Adjust tilt for container-3 */
}

.backroun-img {
  background: url("../images/cover-about-us.jpg") 54%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.corocel-home-two{
  background: url("./images/corocel_images/slide2.jpg") 54%;
}
.carocel-home-three{
  background: url("./images/corocel_images/slide3.jpg") 54%;
}
.carocel-home-four{
  background: url("./images/corocel_images/slide 4.jpg") 54%;
}
.carocel-home-five{
  background: url("./images/corocel_images/slide5.jpg") 54%;
}
.hoem-4th-corocel{
  color:white !important;
}
.carocel-content-header{
  color: rgb(3, 169, 244);
    transition: none 0s ease 0s;
    text-align: inherit;
    line-height: 36px;
    border-width: 0px;
    margin: 20px 0px 0px;
    padding: 0px;
    letter-spacing: 0px;
    font-weight: 600;
    font-size: 36px;
    text-align: start;
}
.carocel-content-discripition{
  font-family: Roboto;
  transition: none 0s ease 0s;
  text-align: left;
  line-height: 30px;
  border-width: 0px;
  margin: 0px;
  padding: 0px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
  
  color: rgb(51, 51, 51);
}
.carocel-content{
  position: absolute;
  top: 40%;
  left: 0%;
  width: 53%;
}    
.background-layer {
  background-color: rgba(0, 0, 0, 0.63);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home {
  /* height: 130vh; */
  /* width: 100vw; */
  /* overflow-y: scroll; */
  /*overflow: hidden;*/ /* Prevent scrollbar from being shown */
  /*-ms-overflow-style: none;*/  /* IE and Edge */
  /* scrollbar-width: none; */ /* Firefox */
}



.content {
  text-align: center;
  color: white;
  word-wrap: break-word;  /* Ensure long words break and wrap to the next line */
  overflow-wrap: break-word; /* Alternative property for older browsers */
  padding: 0 20px; /* Optional padding to give some spacing inside the content */
  max-width: 90%; 
  -webkit-font-smoothing: antialiased;
  font-family: montserrat, helvetica, Arial, sans-serif;
  font-weight: 300;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important;
  overflow: hidden !important;
}

.company-name-content {
  font-size: 8em;
  font-weight: 700;
  margin: 0;
  color: #fff;
  background: #fbf8ec;
  background: linear-gradient(180deg, #fff 35%, #4e6773);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  overflow: hidden;
}

.company-discripition {
  font-size: 1.7em;
  color: #69858e;
}

.section2 {
  height: 100vh;
  width: 100vw;
  background: url("../images/3412_10dialer.jpg") 54%;
  background-size: cover;
  background-repeat: no-repeat;
}




.home-about-us{
  /* background-color: #f8f9fa; */
}

.About-us-tittle span {
  display: block;
  margin: 2% auto;
  /* width: 41px; */
  /* height: 4px; */
  /* background: #acabab; */
  /* display: block; */
  width: 80px;
  height: 3px;
  background-color: #3498DB;
  margin: 10px auto 20px auto;
}
.aboutus-content{
  font-size: 18px !important;
    color: #7F8C8D;
    line-height: 1.8;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
}
.container {
  /* background-color: #f8f9fa; Light grey background for the entire container */
  padding: 20px;
  border-radius: 8px;
}

.card {
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  background-color: #ffffff;
}
.card-title{
  position: relative;
  line-height: 3em;
  text-align: center;
  height: 3em;
}
.about-my-product{
  font-size: 16px !important;
  /* color: #34495E !important; */
  text-align: start;
  color: #7F8C8D !important;
  line-height: 1.8;
  max-width: 700px;
  margin: 0 auto;
  padding: 15px 20px;
  /* background-color: #F9F9F9; */
  border-radius: 8px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  
}
.about-my-products{
  color: #8a61fa;
  margin-right: 5px;
}
.card-title span {
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
  position: relative;
  padding: 0 5%;
  font-size: 23px;
  color: #2a2a2a;
  z-index: 2;
  background: white;
  margin-top: 1em;
 
}
.About-title{
  color: #2C3E50;
  font-weight: bold;
  letter-spacing: 1px;
}

.about-us-contiainer{
  background: white;
  border-radius: 30px;
  margin-top: -100px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title:before {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: 0.5px;
  width: 100%;
  height: 1px;
  background: #c9c9c9;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}


.card-body {
  padding: 20px;
  background: content-box;
  border: none;
  border-radius: 20px;
}
.card-body:hover {
  /* background: white;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 2px 2px 2px 1px #00000070; */
  background: rgba(255, 255, 255, 0.2);
    box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-4px);
    cursor: pointer;
    transform: translateY(-4px);
}
.card-body:hover:before {
  -webkit-animation: colorc 5s linear infinite;
  animation: colorc 5s linear infinite;
  background: -webkit-linear-gradient(left, #c9c9c9, #858484);
  background: -o-linear-gradient(right, #c9c9c9, #858484);
  background: -moz-linear-gradient(right, #c9c9c9, #858484);
  background: linear-gradient(to right, #c9c9c9, #858484);
}
.product-support{
  background: content-box !important;
  border: none !important;
}

.card-text {
  font-size: 1rem;
  color: #333;
  padding:10px
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.me-3 {
  margin-right: 1rem;
}

/* our customers */
.our-customers{
  margin-bottom: 24px;
}
.our-customers-section-title {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2em;
  text-align: center;
  padding: 24px 0px;
}
span.happycust {
  background: linear-gradient(105deg, #8c52ff 6%, #5ce1e6 93%) !important;
  color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text !important;
  background-clip: text !important;
  background: linear-gradient(105deg, #8c52ff 6%, #5ce1e6 93%) !important;
color: transparent; /* Make the text color transparent */
-webkit-background-clip: text !important; /* For WebKit browsers (Chrome, Safari, etc.) */
-moz-background-clip: text !important; /* For Mozilla Firefox */
-webkit-texvt-fill-color: transparent; /* Ensures the text is transparent for WebKit */

}
.our-customer-logo-container{
  display: flex;
  justify-content: center;
}


.custom-carousel .carousel-control-next-icon,
.custom-carousel .carousel-control-prev-icon {
  background-color: #00BCD4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.custom-carousel .carousel-control-next,
.custom-carousel .carousel-control-prev {
  width: 5%; /* Adjust arrow button positioning */
}

.review-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  text-align: center;
}

.review-card h5 {
  font-weight: bold;
  margin-bottom: 10px;
  color: #2C3E50;
}

.review-card .position {
  font-style: italic;
  color: #7F8C8D;
}

.review-card .stars {
  color: #F39C12;
  font-size: 18px;
}
.about-product-discription{
  font-size: 12px;
  color: #7F8C8D;
}
/* gsm product */
.gsm-range{
  display:flex;
  flex-direction: row;
  max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-top:0px;
    font-family: 'Arial', sans-serif;
}
.gsm-content{
  width:60%;
  margin: 10px 0px;
}
.gsm-img{
  width: 40%;
  height: 100%;
    margin: auto;
}
.gsm-img-cont{
  width:100%;
}
.gsm-voice-header{
  text-align: center;
  margin-top: 20px;
}
/* more-product */
.more-product{
  background-image: url('https://www.aegisinformatics.com/images/background/bg5.jpg');
  background-position: bottom center;
  background-repeat: no-repeat;
  position: relative;
}
.more-product-title{
  font-size: 1.5vw;
  color: #FFF;
  margin:30px;
  padding-top: 30px;
}

.all-product-img{
 width:100%;
  margin-bottom: 30px;
  border-radius: 10px;
}
.all-product-img-1{
  margin-left: 30px;
  text-align: center;
}
.overall-product-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
/*.all-product-content {
  width: 13%;
   margin-left: 30px; 
  margin-right: 30px;
}
.product-name {
/*  position: relative;
  top: -129px;
}
.all-prod-overlay {
  position: relative;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6); 
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}*/
/* .all-product-content:hover .all-prod-overlay {
  opacity: 1;
} */
@media (max-width: 768px) {
  .review-card {
    max-width: 100%;
  }
}


@media (max-width: 992px) {
  .company-name-content {
    font-size: 3em !important;
  }
  .company-discripition {
    font-size: 2em  !important;
   
  }
}

@media (max-width: 768px) {
  .company-name-content {
    font-size: 2.5em !important;
  }
  .company-discripition {
    font-size: 1.5em !important;
  }
}

@media (max-width: 576px) {
  .company-name-content {
    font-size: 1.5em !important;
  }
  .our-customers{
    display: flex;
    /* flex-direction: column; */
    align-items: center;
  }
  .our-customers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
    animation: scroll-left 20s linear infinite;
  }
  .all-images{
    display:flex;
  }
  .our-customers-container{
    overflow: hidden;
  }
  .our-customers img {
    width: 120px; /* Optional: Resize for mobile */
    margin: 10px;
  }
  .company-discripition {
    font-size: 1em !important;
  }
  .gsm-range{
    flex-direction: column;
  }
  .gsm-content{
    width:100%;
  }
  .gsm-img{
    width:100%;
  }
  /* .all-product-content{
    width:20%;
  } */
   .home-product-container{
    width:40% !important;
   }
   .more-product-title{
    font-size: 15px;
   }
    @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

@keyframes moveUpDown {
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
}

.container-1, .container-2, .container-3 {
  background-color: #FBF0F1; /* Example background color */
  margin-right: 20px; /* Adjust the margin as needed */
  transform: rotate(-10deg); /* Initial tilt */
  border-radius: 15px; /* Adjust border radius as needed */
  animation: moveUpDown 2s infinite; /* Play animation infinitely */
}

/* New animation for text elements */
@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-up {
  animation: slideUp 1s ease-out;
 
}
.content::-webkit-scrollbar {
  display: none !important; /* Chrome, Safari, Opera */
}
