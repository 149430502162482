.home-product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .home-product-container {
    position: relative;
    /* width: 300px;
    height: 300px; */
    width: 13%;
  }
  
  .home-product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .home-product-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
  }
  
  .home-product-container:hover .home-product-overlay {
    opacity: 1;
  }
  
  .home-product-name {
    font-size: 20px;
    font-weight: bold;
  }
  .home-product-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  
  

  
  .home-product-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;  /* Full width of the container */
    height: 100%; /* Full height of the container */
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
 
  
  .home-product-name {
    font-size: 20px;
    font-weight: bold;
  }
  

  .gsm-img-element{
    width:100%;
  }